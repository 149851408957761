<template>
  <div class="problemList">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <el-button type="primary" size="small" @click="backProblemList"
        >返 回</el-button
      >
      <el-card class="box-card">
        <div style="display: flex; align-items: center">
          <div class="title">搜索题单</div>

          <el-input
            style="width: 30%; border-radius: 40px"
            placeholder="请输入"
            v-model="courseName"
            @change="search"
            class="input-with-select"
            :clearable="true"
            @keyup.enter.native="search"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
        </div>
        <div class="content-left">
          <div class="table-content" v-loading="problemLoading">
            <div class="table" style="margin-top: 20px" v-loading="loading">
              <el-table
                ref="multipleTable"
                :data="data2"
                tooltip-effect="dark"
                style="width: 100%"
                :row-key="(row) => row.id"
              >
                <el-table-column label="来源－创建人－题单名称" align="center">
                  <template slot-scope="scope"
                    ><span>{{ scope.row.title1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="checkBtn1(scope.row)"
                      >查看</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column label="来源－创建人－题单名称" align="center">
                  <template slot-scope="scope"
                    ><span>{{ scope.row.title2 }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      v-if="scope.row.id2"
                      @click="checkBtn2(scope.row)"
                      >查看</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div class="pagination" style="margin-top: 20px">
                <el-pagination
                  background
                  layout="total, sizes, prev, pager, next"
                  :total="total"
                  @current-change="currentChange"
                  :page-size="pageSize"
                  :current-page="page"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <diy-dialog
      class="order-payment-dialog"
      :bodyStyle="{ 'padding-top': 0 }"
      ref="orderPayment"
      width="500px"
      :showFooter="false"
    >
      <order-sure
        type="base"
        @paySuccess="successPay"
        :detail="{ ...currentData, showResult: false }"
        :order_id="currentData.id"
      ></order-sure>
    </diy-dialog>
  </div>
</template>

<script>
import { recommendFieldProblem } from "@/api/topic.js";

import Advertising from "@/components/Advertising/Advertising.vue";
import HotRecommend from "@/views/api/com/hotRecommend";
import ProblemListTable from "@/components/ProblemListTable/ProblemListTable.vue";
import { question_list, detailList } from "@/api/problemList.js";
import { orderCreate } from "@/api/common.js";
import { copyTD } from "@/api/topic.js";
import SearchFilter from "@/components/com/SearchFilter";
import OrderSure from "@/views/api/com/orderSure";
import KnowPoints from "@/views/api/com/knowPoints";
export default {
  components: {
    KnowPoints,
    SearchFilter,
    Advertising,
    HotRecommend,
    ProblemListTable,
    OrderSure,
  },
  data() {
    return {
      where: {},
      params: {},
      isClick: true,
      showMine: false,
      operationTable: {
        label: "题单名称",
        isSelection: false,
        time_str: true,
        tableData: [],
        total: 0,
      },
      page: 1,
      pageSize: 50,
      total: 0,
      loading: false,
      tableData: [],
      data2: [],
      total: 0,

      //分页相关
      page: 1,
      page_size: 9,
      problemLoading: false,
      currentData: {},
      payType: "",
      courseName: "",
    };
  },
  watch: {
    isClick(v) {
      if (!v) {
        this.$nextTick(() => {
          this.isClick = true;
        });
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      this.loading = true;
      // this.$http.post(`/xapi/question.question/myList?page=`+this.page+'&pageSize='+this.pageSize,{keyword:this.courseName}).then((res)=>{
      this.$http
        .post(
          `//xapi/question.question_list/myList?page=` +
            this.page +
            "&pageSize=" +
            this.pageSize,
          { keyword: this.courseName }
        )
        .then((res) => {
          console.log(res, "liebbbbb");
          this.loading = false;

          this.tableData = res.data.data;
          // let arr = [
          //   { id: 1, name: "测试1" },
          //   { id: 2, name: "测试2" },
          //   { id: 3, name: "测试3" },
          //   { id: 4, name: "测试4" },
          //   { id: 5, name: "测试5" },
          //   { id: 6, name: "测试6" },
          //   { id: 7, name: "测试7" },
          //   { id: 8, name: "测试8" },
          //   { id: 9, name: "测试9" },
          //   { id: 10, name: "测试10" },
          //   { id: 11, name: "测试11" },
          //   { id: 12, name: "测试12" },
          //   { id: 13, name: "测试13" },
          //   { id: 13, name: "测试13" },
          // ];
          // let newArr = this.tableData.reduce((acc, curr, index, array) => {
          let newArr = this.tableData.reduce((acc, curr, index, array) => {
            if (index % 2 === 0) {
              acc.push({
                id1: curr.id,
                title1: curr.title,
                id2: index + 1 < array.length ? array[index + 1].id : "",
                title2: index + 1 < array.length ? array[index + 1].title : "",
              });
            }
            return acc;
          }, []);
          this.data2=newArr
          console.log(this.data2,"newArr");
          // for (let i = 0; i < this.tableData.length; i += 2) {
          //   if (i + 1 < this.tableData.length) {
          //     // 合并每对相邻的项
          //     const item1 = this.tableData[i];
          //     const item2 = this.tableData[i + 1];
          //     this.data2.push({
          //       title1: item1.title,
          //       title2: item2.title,
          //       id1: item1.id,
          //       id2: item2.id,
          //     });
          //   } else {
          //     // 如果列表长度为奇数，最后一个元素没有配对，可以选择忽略或单独处理
          //     const item1 = this.tableData[i];
          //     // const item2 = this.tableData[i + 1];
          //     this.data2.push({
          //       title1: item1.title,
          //       id1: item1.id,
          //       // title2: item2.title,
          //     });
          //     console.log(
          //       "List has an odd number of items, last item will be ignored."
          //     );
          //   }
          // }
          console.log(this.data2, "this.data2");
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 是否推荐
    setRecommend(row, val) {
      this.$confirm(`是否${val == "1" ? "推荐" : "取消推荐"}该题单？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let data = {
            field: "recommend",
            id: row.id,
            value: val,
          };
          recommendFieldProblem(data)
            .then((res) => {
              this.$message({
                type: "success",
                message: val == "1" ? "推荐成功" : "取消推荐成功",
              });
            })
            .catch((err) => {
              console.log(err, "err");
              if (val == "0") {
                row.recommend = "1";
              } else {
                row.recommend = "0";
              }
            });
        })
        .catch(() => {
          console.log("取消发布");
          if (val == "0") {
            row.recommend = "1";
          } else {
            row.recommend = "0";
          }
        });
    },
    // 显示状态
    setShowType(row, val) {
      this.$confirm(`是否${val == "1" ? "显示" : "取消显示"}该题单？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let data = {
            field: "show_type",
            id: row.id,
            value: val,
          };
          recommendFieldProblem(data)
            .then((res) => {
              this.$message({
                type: "success",
                message: val == "1" ? "显示成功" : "取消显示成功",
              });
            })
            .catch((err) => {
              console.log(err, "err");
              if (val == "0") {
                row.show_type = "1";
              } else {
                row.show_type = "0";
              }
            });
        })
        .catch(() => {
          console.log("取消发布");
          if (val == "0") {
            row.show_type = "1";
          } else {
            row.show_type = "0";
          }
        });
    },
    checkBtn2(e) {
      console.log(e, "eeeeeeeee");
      console.log(e.id2, "e.id2");
    },
    checkBtn1(e) {
      console.log(e, "eeeeeeeee");
      console.log(e.id1, "e.id1");
    },
    backProblemList() {
      this.$router.replace({ path: "/problemList" });
    },
    search() {
      console.log(this.courseName, "this.courseName");
      this.getList();
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },

    handleEditChange(v, ov, { row, column }) {
      this.$http
        .request({
          url: "/xapi/question.quest_list/updateField",
          params: {
            field: column.prop,
            id: row.id,
            value: v,
          },
        })
        .catch(() => {
          row[column.prop] = ov;
        });
    },

    routeUpdate() {
      this.where = { ...this.$route.query, ...this.$route.query.params };
    },
    newCopy(e) {
      this.currentData = e;
      this.payType = "copy";
      this.orderCreate(e, this.successPay);
    },
    handlerCopyTD(e) {
      this.problemLoading = true;
      copyTD({ question_list_id: e.id })
        .then((res) => {
          console.log(res.data.id);
          detailList({ id: res.data.id })
            .then((res) => {
              this.$store.state.topicType = {
                name: "编辑题单",
                detail: res.data,
              };
              this.$router.push({
                path: "/problemListOperate",
                query: { id: e.id },
              });
            })
            .finally((error) => {
              this.problemLoading = false;
            });
        })
        .catch(() => {
          this.problemLoading = false;
        });
    },
    questionList(param = {}) {
      this.problemLoading = true;

      question_list({
        ...this.where,
        ...this.params,
        ...(param || {}),
        page: this.page,
        page_size: this.page_size,
      })
        .then(({ data }) => {
          this.operationTable.total = data.total;
          this.operationTable.tableData = data.data;
          this.$store.commit("SET", { key: "headerValue.flag", value: false });
        })
        .finally(() => {
          this.problemLoading = false;
          this.tag_id = "";
        });
    },
    currentChange(e) {
      this.page = e;
      this.questionList();
    },
    setWherePrams(params, isOverride = false) {
      params || {};
      this.page = 1;
      if (isOverride) {
        this.params = params;
      } else {
        this.params = { ...this.params, ...params };
      }
      this.questionList();
    },
    goNewProblemList() {
      if (this.userInfo.type + "" === "2") {
        this.$handleRoute("teachingQuestListCreate");
      } else {
        this.$store.state.topicType = {
          name: "新建题单",
          detail: {},
        };
        this.$router.push({ path: "/problemListOperate" });
      }
    },
    orderCreate(e, callback) {
      if (!(this.userInfo.id > 0)) {
        this.$store.state.lodinShow = true;
      } else {
        if (e.is_buy + "" === "0") {
          //创建订单
          orderCreate({
            type: 3,
            id: e.id,
          }).then((res) => {
            //已支付
            if (res.data.is_pay + "" === "1") {
              this.$message({
                message: "支付成功",
                type: "success",
              });
              callback && callback();
            } else {
              this.currentData = res.data.order;
              this.$refs.orderPayment.visibled(true);
            }
          });
        } else if (e.is_buy + "" === "1") {
          callback && callback();
        }
      }
    },
    //下载
    download(e) {
      this.currentData = e;
      this.payType = "download";
      this.orderCreate(e, this.successPay);
    },
    //支付成功
    successPay() {
      this.problemLoading = false;
      if (this.payType === "download") {
        this.$tool.fileResponse(this.currentData.down_url);
      } else {
        this.handlerCopyTD(this.currentData);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  margin-top: 20px;
  .title {
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
  }
  ::v-deep {
    .el-input__inner {
      border-radius: 40px 0 0 40px;
      height: 32px;
    }
    .el-table__row {
      .el-input__inner {
        border-radius: 4px !important;
        height: 32px;
      }
    }
    .el-input-group__append {
      border-radius: 0 40px 40px 0;
      background: #00957e;
      color: #ffffff;
    }
    .border-base {
      text-align: center;
      border-radius: 20px;
      padding: 10px;
      border: 1px solid #ccc;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.content-left {
  // width: 70%;
  width: 100%;
  .operate {
    margin: 2.2% 0;
    // margin-top: 40px;
    // margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }
}
</style>